<template>
  <main class="container">
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_status ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmStatus()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Update Loan Product Status
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmStatus()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="updateStatus()"
                >
                  {{
                    process
                      ? "Processing..."
                      : loanProductStatus
                      ? "Deactivate"
                      : "Activate"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--CONFIRM DELETE DIALOG-->
    <div
      class="modal modal__confirm show"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm_delete ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmDelete()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              Delete Loan Product
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmDelete()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="deleteItem()"
                >
                  {{ process ? "Processing..." : "Delete" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DeleteModal
      :show="!userCanViewLoanProduct"
      :title="'Unauthorized'"
      @close="goToDashboard"
    >
      <template v-slot:modal-body>
        <div class="modal__content">
          <div class="modal__body">
            <h5 class="modal__head__title text--capital mb-3">Unauthorized</h5>
            <!-- <h5 class="text-danger">
              You don
            </h5> -->
            <div class="d-flex mt-5">
              <button
                data-dismiss="#confirmModal"
                class="button form__button form__button--lg save-changes fitrem"
                @click.prevent="goToDashboard"
              >
                Go to Dashboard
              </button>
            </div>
          </div>
        </div>
      </template>
    </DeleteModal>

    <CreatePlan
      v-bind:show="showCreateModal"
      v-bind:close="closeCreateModal"
      v-bind:enums="enums"
      @close="closeCreateModal"
      @alertSuccess="alertSuccess"
      @alertWarning="alertWarning"
      @reload="getList"
      @alertError="alertError"
      @error-creating-product="alertError"
      :depositAccounts="depositAccounts"
    />

    <CreateLoanAccount
      v-bind:show="showCreateAccountModal"
      v-bind:loadingProducts="loading"
      v-bind:products="products ? products.data : []"
      @close="closeCreateAccountModal"
      @alertSuccess="alertSuccess"
      @reload="getList"
      @alertError="alertError"
      :depositAccounts="depositAccounts"
    />

    <EditPlan
      v-bind:show="showEditModal"
      v-bind:close="closeEditModal"
      v-bind:enums="enums"
      @close="closeEditModal"
      @alertSuccess="alertSuccess"
      @alertWarning="alertWarning"
      @alertError="alertError"
      @reload="getList"
      @error-creating-product="alertError"
      v-bind:loanProduct="loanProduct"
      :loadingDepositAccounts="loadingDepositAccounts"
      :depositAccounts="depositAccounts"
    />
    <CopyPlan
      v-bind:show="showCopyModal"
      v-bind:close="closeCopyModal"
      v-bind:enums="enums"
      @close="closeCopyModal"
      @alertSuccess="alertSuccess"
      @alertWarning="alertWarning"
      @alertError="alertError"
      @reload="getList"
      v-bind:loanProduct="loanProduct"
      :loadingDepositAccounts="loadingDepositAccounts"
      :depositAccounts="depositAccounts"
      @error-creating-product="alertError"
    />

    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="page__body__header">
        <div class="row align-items-center justify-content-between">
          <div class="col-8">
            <label
              for="text"
              class="has-checkbox page__body__header__label text--capital"
              >Show deactivated products
              <input
                type="checkbox"
                id="text"
                class="form-"
                @change="toggleStatus($event)"
                :checked="!status"
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <div class="col-4 ml-auto">
            <div class="row align-items-center justify-content-between">
              <div class="col-4">
                <div class="d-flex justify-content-between">
                  <!--                <b-dropdown-->
                  <!--                  html="Filter By <ion-icon name='caret-down-outline'/>"-->
                  <!--                  no-caret-->
                  <!--                  class="m-2 page__body__header__button dropdown&#45;&#45;button-grey w-100"-->
                  <!--                >-->
                  <!--                  <b-dropdown-item href="#">Active</b-dropdown-item>-->
                  <!--                  <b-dropdown-item href="#">InActive</b-dropdown-item>-->
                  <!--                  <b-dropdown-item href="#">Pending</b-dropdown-item>-->
                  <!--                  <b-dropdown-item href="#">Exited</b-dropdown-item>-->
                  <!--                  <b-dropdown-item href="#">Black Listed</b-dropdown-item>-->
                  <!--                </b-dropdown>-->
                  <b-dropdown
                    :html="filter_by_label"
                    no-caret
                    class="m-2 page__body__header__button dropdown--button-grey w-100"
                    v-model="category"
                  >
                    <b-dropdown-item
                      v-for="item in categories"
                      :key="item.id"
                      v-bind:value="item.value"
                      @click="filterByCategory(item.value, item.name)"
                      >{{ item.name }}</b-dropdown-item
                    >
                  </b-dropdown>
                  <!--                  <b-form-select class="m-2 page__body__header__button dropdown&#45;&#45;button-grey w-100" v-model="category" :options="categories"></b-form-select>-->
                </div>
              </div>

              <div class="col-7 ml-3" v-if="userCanCreateLoanProduct">
                <button
                  type="button"
                  class="button w-100 page__body__header__button button--black text--capital"
                  @click="openCreateModal()"
                >
                  <span class="d-inline-flex mr-2">new loan product</span>
                  <ion-icon name="add-outline" class="text--regular"></ion-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5">
            <div class="form__item">
              <input
                type="text"
                class="input form__input form__input--lg"
                v-model="searchProductName"
                @input="searchProductByName"
                id="searchProduct"
                placeholder="Search for Product Name"
              />
            </div>
          </div>
        </div>
      </div>
      <table class="customtable w-100">
        <thead>
          <tr>
            <ZedvanceTableTh
              v-for="(item, index) in columns"
              v-bind:key="index"
              v-bind:title="item.title"
            />
          </tr>
        </thead>
        <tbody v-if="loading">
          <tr>
            <td colspan="5">
              <div class="d-flex align-items-center justify-content-center p5">
                <div
                  class="spinner-border"
                  style="width: 1rem; height: 1rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span
                  class="text--black text--600 text--medium d-inline-flex ml-4"
                  >Please wait</span
                >
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="products">
          <tr v-for="(row, ri) in products.data" v-bind:key="ri">
            <td style="padding-right: 15px !important">
              <span
                class="table__body__text table__text text--capital"
                style="padding-right: 15px; word-break: break-all"
              >
                {{ row.userDefinedLoanId }}
              </span>
            </td>
            <td style="padding-right: 15px !important">
              <span
                class="table__body__text table__text text--capital"
                style="padding-right: 15px; word-break: break-word"
              >
                {{ row.productName }}
              </span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">
                {{
                  row.loanProductType
                    ? row.loanProductType === "fixed_term_loan"
                      ? "Fixed Term Loan"
                      : row.loanProductType
                    : "NA"
                }}
              </span>
            </td>
            <td>
              <span class="table__body__text table__text text--capital">
                {{ format_date(row.lastModifiedDate) }}
              </span>
            </td>
            <td>
              <span
                class="table__body__text table__text text--capital"
                :class="row.activated ? 'text--green' : 'text--danger'"
              >
                {{ row.activated ? "Active" : "Deactivated" }}
              </span>
            </td>
            <td>
              <b-dropdown text="Action" class="m-2 dropdown--button-grey">
                <b-dropdown-item
                  href="#"
                  @click="openEditModal(row)"
                  v-if="userCanEditLoanProduct"
                  >Edit</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="openCopyModal(row)"
                  v-if="userCanCreateLoanProduct"
                  >Copy Product</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="confirmStatus(row)"
                  v-if="row.activated && userCanDeactivateLoanProduct"
                  >{{ "Deactivate" }}</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="confirmStatus(row)"
                  v-if="!row.activated && userCanActivateLoanProduct"
                  >{{ "Activate" }}</b-dropdown-item
                >
                <b-dropdown-item
                  href="#"
                  @click="confirmDelete(row)"
                  v-if="userCanDeleteLoanProduct"
                  >Delete</b-dropdown-item
                >
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination" v-if="products && !loading">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing
            <span class="d-inline-block text--primary">{{
              products ? products.currentPage : 0
            }}</span>
            to
            <span class="d-inline-block text--primary">{{
              products ? products.totalPages : 0
            }}</span>
            of
            <span>{{ products ? products.totalCount + 1 : 0 }}</span>
          </div>
          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
            v-if="products"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!products.hasPrevious"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              v-for="page in products.totalPages"
              :key="page"
              :class="{
                'text--white button--primary ': products.currentPage === page,
              }"
              class="button text--regular button--sm ml-2"
              @click="changePage(page)"
            >
              {{ page }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!products.hasNext"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import CreatePlan from "./create";
import CreateLoanAccount from "./create_account";
import EditPlan from "./edit";
import CopyPlan from "./copy";
import { GET_LOAN_PRODUCTS } from "@/core/services/store/types";
import LoanApiService from "../../../core/services/api.loan.service";
const product_enums = require("./config_modules/products_enums");
import DeleteModal from "@/components/DeleteModal/_base-delete-modal.vue";
import { Role } from "@/core/services/role.js";
import ApiService from "@/core/services/api.service";
export default {
  name: "LoansIndex",
  components: {
    CreatePlan: CreatePlan,
    CreateLoanAccount: CreateLoanAccount,
    EditPlan: EditPlan,
    CopyPlan: CopyPlan,
    DeleteModal,
  },
  data: function () {
    return {
      enums: product_enums,
      page: 1,
      size: 20,
      status: true,
      category: null,
      confirm_status: false,
      confirm_delete: false,
      loanProduct: null,
      loanProductKey: "",
      loanProductStatus: false,
      alert: {
        show: false,
        status: "",
        title: "Success",
        description: "Loan Product has been created",
      },
      process: false,
      showCreateModal: false,
      showCreateAccountModal: false,
      showEditModal: false,
      showCopyModal: false,
      filter_by: "deactivated",
      filter_by_label: "Sort By <ion-icon name='caret-down-outline'/>",
      content_header: {
        title: "Loans",
        subTitle:
          "Manage the products offerings that you have available to your customers by creating and editing loan products.",
      },
      columns: [
        {
          title: "ID",
        },
        {
          title: "Product",
        },
        {
          title: "Type",
        },
        {
          title: "Last Modified",
        },
        {
          title: "Status",
        },
        {
          title: "",
        },
      ],
      loadingDepositAccounts: false,
      depositAccounts: [],
      subCat: "",
      sorted: true,
      categories: [
        {
          id: 0,
          name: "Product Name",
          description: "productname",
          value: "product_name",
        },
        {
          id: 1,
          name: "Product Type",
          description: "producttype",
          value: "product_type",
        },
        {
          id: 2,
          name: "Last Modified Date",
          description: "lastmodifieddate",
          value: "last_modified_date",
        },
      ],
      searchProductName: "",
    };
  },
  methods: {
    goToDashboard() {
      this.$router.push({ path: "/dashboard" });
    },
    toggleStatus(e) {
      this.status = e.target.checked ? false : true;
      this.$store.dispatch(GET_LOAN_PRODUCTS, {
        page: this.products.currentPage,
        size: this.size,
        activestatus: e.target.checked ? false : true,
        category: this.category,
        productname: this.searchProductName,
      });
    },
    getDepositAccounts() {
      this.loadingDepositAccounts = true;
      const self = this;
      ApiService.get("DepositAdministration/GetDepositProductSettlementAccount")
        .then((res) => {
          this.depositAccounts = res.data;
          // if (res) {
          //   this.depositAccounts = res.data;
          // }
          self.loadingDepositAccounts = false;
        })
        .catch(() => {
          self.loadingDepositAccounts = false;
        });
    },
    getList() {
      this.$store.dispatch(GET_LOAN_PRODUCTS, {
        page: this.page,
        size: this.size,
        activestatus: this.status,
        category: this.category,
        sortColumn: this.subCat,
        sort: this.sorted,
        productname: this.searchProductName,
      });
      if (!this.userCanViewLoanProduct)
        return this.alertWarning(
          "You do not have permission to view this page"
        );
    },
    searchProductByName() {
      this.getList();
    },
    changePage(page = null) {
      if (page === "next") {
        const nextPage = this.products.hasNext
          ? this.products.currentPage + 1
          : 0;
        this.$store.dispatch(GET_LOAN_PRODUCTS, {
          page: nextPage,
          size: this.size,
          activestatus: this.$data.status,
          category: this.category,
          sortColumn: this.subCat,
          sort: this.sorted,
          productname: this.searchProductName,
        });
      } else if (page === "previous") {
        const nextPage = this.products.hasPrevious
          ? this.products.currentPage - 1
          : 0;
        this.$store.dispatch(GET_LOAN_PRODUCTS, {
          page: nextPage,
          size: this.size,
          activestatus: this.$data.status,
          category: this.category,
          sortColumn: this.subCat,
          sort: this.sorted,
          productname: this.searchProductName,
        });
      } else if (page !== null) {
        this.$store.dispatch(GET_LOAN_PRODUCTS, {
          page: page,
          size: this.size,
          activestatus: this.$data.status,
          category: this.category,
          sortColumn: this.subCat,
          sort: this.sorted,
          productname: this.searchProductName,
        });
      }
    },
    confirmStatus(row = null) {
      if (row) {
        this.loanProductKey = row.loanProductKey;
        this.loanProductStatus = row.activated;
      }

      this.alert.show = false;
      if (!this.confirm_status) {
        this.confirm_status = true;
      } else {
        this.confirm_status = false;
      }
    },
    confirmDelete(row) {
      if (row) {
        this.loanProductKey = row.loanProductKey;
        this.loanProductStatus = row.activated;
      }
      this.alert.show = false;
      if (!this.confirm_delete) {
        this.confirm_delete = true;
      } else {
        this.confirm_delete = false;
      }
    },
    updateStatus() {
      const payload = {
        loanProductKey: this.loanProductKey,
        userId: "string",
      };
      const action = this.loanProductStatus
        ? "LoanProduct/deactivateloanproduct"
        : "LoanProduct/activateloanproduct";
      this.process = true;
      LoanApiService.post(action, payload)
        .then((res) => {
          this.process = false;
          this.confirm_status = false;
          if (res.data.status) {
            this.getList();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_status = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    deleteItem() {
      const payload = {
        loanProductKey: this.loanProductKey,
        userId: "string",
      };
      const action = "LoanProduct/deleteloanproduct";
      this.process = true;
      LoanApiService.post(action, payload)
        .then((res) => {
          this.process = false;
          this.confirm_delete = false;
          if (res.data.status) {
            this.getList();
            this.alertSuccess(res.data.message);
          } else {
            this.alertError(res.data.message);
          }
        })
        .catch((e) => {
          this.confirm_delete = false;
          try {
            this.process = false;
            this.alertError(e.response.data.message);
          } catch (e) {
            this.alertError(
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description ? description : "Success";
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description
        ? description
        : "Error completing Action";
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    openCreateModal() {
      this.showCreateModal = true;
      this.alert.show = false;
    },
    openCreateAccountModal() {
      this.showCreateAccountModal = true;
      this.alert.show = false;
    },
    openEditModal(row = null) {
      this.loanProduct = row;
      this.showEditModal = true;
      this.alert.show = false;
    },
    openCopyModal(row = null) {
      this.loanProduct = row;
      this.showCopyModal = true;
      this.alert.show = false;
    },
    closeCreateModal() {
      this.showCreateModal = false;
      this.alert.show = false;
      this.getList();
    },
    closeCreateAccountModal() {
      this.showCreateAccountModal = false;
      this.alert.show = false;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.alert.show = false;
      this.getList();
    },
    closeCopyModal() {
      this.showCopyModal = false;
    },
    filterByCategory(id, name) {
      this.subCat = id;
      (this.sorted = false),
        (this.filter_by_label =
          name + " <ion-icon name='caret-down-outline'/>");
      this.$store.dispatch(GET_LOAN_PRODUCTS, {
        page: this.products.currentPage,
        size: this.size,
        activestatus: this.$data.status,
        category: "",
        sortColumn: id,
        sort: this.sorted,
        productname: this.searchProductName,
      });
    },
    format_date(value) {
      if (value) {
        return moment(value).format("Do-MMM-YYYY");
      }
    },
  },
  watch: {
    category: function (val) {
      this.filter_by_label = val + " <ion-icon name='caret-down-outline'/>";
      this.$store.dispatch(GET_LOAN_PRODUCTS, {
        page: this.products.currentPage,
        size: this.size,
        activestatus: this.$data.status,
        category: val,
      });
    },
  },
  computed: {
    ...mapState({
      products: (state) => state.loan.products,
      // categories: (state) =>
      //   state.loan.enums.productCategory.map((item, i) => {
      //     if (i === 0) {
      //       return { value: null, text: "" };
      //     } else {
      //       return { value: item.value, text: item.name };
      //     }
      //   }),
      loading: (state) => state.loan.loading,
      saving: (state) => state.loan.saving,
      user: (state) => state.auth.user,
    }),

    userCanViewLoanProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanViewLoanProduct;
    },

    userCanEditLoanProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanEditLoanProduct;
    },

    userCanCreateLoanProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanCreateLoanProduct;
    },

    userCanDeleteLoanProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanDeleteLoanProducts;
    },

    userCanDeactivateLoanProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanDeactivateLoanProduct;
    },

    userCanActivateLoanProduct() {
      const account = this.user.scopes.find(
        (item) => item.scopeName === Role.Product
      );
      const { permissionsList } = account;
      return permissionsList.CanActivateLoanProduct;
    },
    // CanDeactivateSavingsProduct
  },
  mounted() {
    this.getList();
    this.getDepositAccounts();
  },
};
</script>
